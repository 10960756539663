'use client';

import React from 'react';
import Image from 'next/image';
import { Anchor, Button } from '@grupa-pracuj/ui-library-relax';

import useEnvironment from 'src/context/EnvironmentContext';
import image from 'src/assets/images/error_default.svg';

import {
  headlineText,
  paragraphText,
  redirectToAccountText,
  redirectToAccountTextPrefix,
  refreshPageText,
} from './ErrorTemplate.constants';
import styles from './ErrorTemplate.module.css';

function ErrorTemplate({ reset }: { reset: () => void }) {
  const { homePageUrl } = useEnvironment();

  return (
    <div className={styles.errorWrapper}>
      <h1 className={styles.headline}>{headlineText}</h1>
      <Image src={image} alt="" role="presentation" />
      <p className={styles.paragraph}>{paragraphText}</p>
      <div className={styles.buttonsWrapper}>
        <Button onClick={reset}>{refreshPageText}</Button>
        <span>
          {redirectToAccountTextPrefix} <Anchor href={homePageUrl}>{redirectToAccountText.toLowerCase()}</Anchor>
        </span>
      </div>
    </div>
  );
}

export default ErrorTemplate;
